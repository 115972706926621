@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';
.form {
    width: 100%;
    text-align: center;
    padding: 20px 40px 0 40px;
    .form-banner {
        width: 100%;
        background: #165ebf;
        height: 56px;
        color: white;
        text-align: center;
        line-height: 56px;
        margin-bottom: 24px;
        border-radius: 10px;
    }

    .contact-form {
        width: 100%;
        padding: 20px 0;
    }

    .form-input {
        display: none;
    }
}
