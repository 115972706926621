.post-status-decor:before {
    background-color: lightgray;
    width: 3px;
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 8px;
}

.post-status-decor:first-child:before { top: 22px;  }

.post-status-decor:last-child:before { height: 5px; }
