@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

//userSignIn
.remember-me {
    margin-top: 0.5rem !important;
}
.auth-button {
    margin-top: 31px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: 27px !important;
}
.no-transform {
    text-transform: none !important;
}
.forgot-password {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 19px !important;
    color: #6c757d !important;
}


